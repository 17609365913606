import PageHeader from './components/page-header'
import Loading from './components/loading'
import { useEffect, useState } from 'react'
import { api, api_delete } from './api.service'
import PcCard from './components/pc-card'
import Button from './components/button'
import InvestorCard from './components/investor-card'
import Table from './components/table'
import Confirm from './components/confirm'
import { useNavigate, useParams } from "react-router-dom";
import Dashboard from './dashboard'

export default function PcDataDashboard() {
  const params = useParams();
  const [data, setData] = useState();
  useEffect(() => { api(`${process.env.REACT_APP_API_URL}/pc`).then(x => { { console.log(x); setData(x.portfolioCompany) }; }); }, [])
  if (!data) return <Loading></Loading>

  return (<>
    <PageHeader headline={data.dashboards_pc?.find(x => x._id == params.id)?.name} ></PageHeader>
    <div className="max-w-6xl w-full mx-auto">
      <Dashboard metrics={data.metrics} _metricValues={data.metricValues} dashboard={data.dashboards_pc?.find(x => x._id == params.id)}></Dashboard>
    </div>
  </>
  )
}