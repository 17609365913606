import PageHeader from "../components/page-header";
import ImageField from "./fields/image-upload";
import TextAreaField from "./fields/text-area-field";
import TextField from "./fields/text-field";
import FormWrapper from "./form-wrapper";
import { useNavigate, useParams } from "react-router-dom";
import DateField from "./fields/date-field";
import SelectField from "./fields/select-field";
import CheckboxField from "./fields/checkbox-field";
import Button from "../components/button";
import EquationBuilder from "./fields/equation-builder";
import { TrashIcon } from "@heroicons/react/20/solid";
import NumericBooleanBuilder from "./fields/numeric-boolean-builder";

export default function MetricForm(props) {
    const params = useParams();
    let id = params.id ?? props.id;
    const navigate = useNavigate();
    return <div>
        <PageHeader showBackButton headline={`Manage ${props.category == "metrics" ? 'Metric' : props.category == "esg" ? 'ESG Metric' : 'Data Point'}`}></PageHeader>
        <FormWrapper defaultValue={{ systemCategory: props.category == "metrics" ? 1 : props.category == "esg" ? 2 : 0 }} url="admin/metric" id={id} {...props} callback={() => navigate(-1)}>
            {(values, setValues) => <div className="">

                <div className="text-neutral-800 border-b border-neutral-200 pb-1 mb-2 font-semibold text-2xl">General</div>

                <TextField label="Name" value={values.name} onChange={(val) => setValues({ ...values, name: val })}></TextField>

                <div className="grid grid-cols-2">
                    <CheckboxField label="Computational Metric" value={values.computational} onChange={(val) => setValues({ ...values, computational: val })}></CheckboxField>
                    <CheckboxField label="Admin Only" value={values.adminOnly} onChange={(val) => setValues({ ...values, adminOnly: val })}></CheckboxField>
                </div>



                {values.computational && <div className="col-span-2">

                    <EquationBuilder label="Equation" value={values.equation} onChange={(val) => setValues({ ...values, equation: val })}></EquationBuilder>

                </div>}

                {!values.computational && <>

                    <SelectField optionListName="data-types" label="Type" value={values.type} onChange={(val) => setValues({ ...values, type: val })}></SelectField>
                    {values.type === 2 && <TextField label="Unit" value={values.unit} onChange={(val) => setValues({ ...values, unit: val })}></TextField>}

                </>}

                {(values.type === 6 || values.type === 7) && <div className="col-span-2 my-5 p-3 bg-white">
                    Selection Options
                    {values.options?.map((option, optionIndex) => <div key={option.id} className="grid grid-cols-2 gap-2 relative">
                        <div className="absolute right-0" onClick={() => setValues({ ...values, options: values.options.filter((y, j) => j !== optionIndex) })}><TrashIcon className="w-6 text-neutral-600 hover:text-neutral-800 cursor-pointer"></TrashIcon></div>
                        <TextField label="Option Text" value={option.text} onChange={(val) => setValues({ ...values, options: values.options.map((y, j) => j === optionIndex ? { ...y, text: val } : y) })}></TextField>
                        <TextField label="Option Value" value={option.value} onChange={(val) => setValues({ ...values, options: values.options.map((y, j) => j === optionIndex ? { ...y, value: val } : y) })}></TextField>

                    </div>)}
                    <div className="my-3 text-sm underline text-neutral-800 cursor-pointer" onClick={() => setValues({ ...values, options: values.options ? values.options.concat([{}]) : [{}] })}>Add Option</div>
                </div>}

                <div className="grid grid-cols-3 gap-2">
                    <TextField label="Category" value={values.category} onChange={(val) => setValues({ ...values, category: val })}></TextField>
                    {values.computational && <TextField label="Unit" value={values.unit} onChange={(val) => setValues({ ...values, unit: val })}></TextField>}
                    <SelectField optionListName="intervals" label="Interval" value={values.interval} onChange={(val) => setValues({ ...values, interval: val })}></SelectField>
                </div>


                <div className="text-neutral-800 border-b border-neutral-200 pb-1 my-2 font-semibold text-2xl">Flag Rules</div>


                <div className="col-span-3 p-2">

                    {values.flagRules?.map((rule, ruleIndx) => <div className="flex border-b p-2 space-x-2 relative">
                        <div className="absolute right-0 top-0" onClick={() => setValues({ ...values, flagRules: values.flagRules.filter((x, i) => i !== ruleIndx) })}><TrashIcon className="w-4 text-neutral-600 hover:text-neutral-800 cursor-pointer"></TrashIcon></div>

                        <div className="flex-1">
                            {/* Computational  */}
                            {(values.computational) && <>
                                <NumericBooleanBuilder value={rule.value} onChange={(val) => setValues({ ...values, flagRules: values.flagRules.map((z, l) => l === ruleIndx ? { ...z, value: val } : z) })}></NumericBooleanBuilder>
                            </>}

                            {/* Answered or not  */}
                            {[0, 1, 9].includes(values.type) && <>
                                <SelectField options={[{ text: 'Answered', value: true }, { text: 'Not Answered', value: false }]} label="Metric" value={rule.value} onChange={(val) => setValues({ ...values, flagRules: values.flagRules.map((z, l) => l === ruleIndx ? { ...z, value: val } : z) })}></SelectField>
                            </>}

                            {/* Numeric */}
                            {[2, 3, 4].includes(values.type) && <>
                                <NumericBooleanBuilder value={rule.value} onChange={(val) => setValues({ ...values, flagRules: values.flagRules.map((z, l) => l === ruleIndx ? { ...z, value: val } : z) })}></NumericBooleanBuilder>
                            </>}

                            {/* Date */}
                            {values.type == 5 && <>
                                <NumericBooleanBuilder value={rule.value} onChange={(val) => setValues({ ...values, flagRules: values.flagRules.map((z, l) => l === ruleIndx ? { ...z, value: val } : z) })}></NumericBooleanBuilder>
                            </>}

                            {/* Select */}
                            {[6, 7].includes(values.type) && <>
                                <SelectField options={values.options} label="Metric" value={rule.value} onChange={(val) => setValues({ ...values, flagRules: values.flagRules.map((z, l) => l === ruleIndx ? { ...z, value: val } : z) })}></SelectField>
                            </>}

                            {/* Yes No */}
                            {values.type == 8 && <>
                                <SelectField options={[{ text: 'Yes', value: true }, { text: 'No', value: false }]} label="Metric" value={rule.value} onChange={(val) => setValues({ ...values, flagRules: values.flagRules.map((z, l) => l === ruleIndx ? { ...z, value: val } : z) })}></SelectField>
                            </>}

                        </div>
                    </div>)}
                    <Button className="mt-5" text="Add Flag Rule" onClick={() => setValues({ ...values, flagRules: values.flagRules ? values.flagRules.concat([{}]) : [{}] })}></Button>
                </div>
            </div>}
        </FormWrapper>
    </div>
}