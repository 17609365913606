import PageHeader from './components/page-header'
import Loading from './components/loading'
import { useEffect, useState } from 'react'
import { api, api_delete } from './api.service'
import PcCard from './components/pc-card'
import { useNavigate } from 'react-router-dom'
import Button from './components/button'
import InvestorCard from './components/investor-card'
import Table from './components/table'
import Confirm from './components/confirm'
import { PencilIcon, TrashIcon } from '@heroicons/react/20/solid'

export default function AdminDashboards() {
  const navigate = useNavigate();
  const [data, setData] = useState(null)
  const [deleteId, setDeleteId] = useState();
  const deleteConfirm = () => {
    api_delete(`${process.env.REACT_APP_API_URL}/admin/dashboard/${deleteId}`).then(x => {
      api(`${process.env.REACT_APP_API_URL}/admin/dashboard`).then(x => setData(x)).then(x => setDeleteId(null))
    });
  }

  useEffect(() => { api(`${process.env.REACT_APP_API_URL}/admin/dashboard`).then(x => setData(x)) }, [])

  if (!data) return <Loading></Loading>

  return (<>
    <Confirm open={!!deleteId} cancel={() => setDeleteId(false)} confirm={() => deleteConfirm(deleteId)}></Confirm>

    <PageHeader subtitle={"Manage custom general & bespoke dashboards"} headline={"Dashboards"} actions={<Button text="Add Dashboard" onClick={() => navigate('/2/manage-dashboard/new')}></Button>}></PageHeader>
    <div className="flex">
      <div className="w-full pb-20 max-w-6xl mx-auto my-10">
        <div>
          <Table
            data={data.map(x => {
              return {
                ...x,
                edit: <span className="cursor-pointer underline" onClick={() => navigate(`/2/manage-dashboard/${x._id}`)}><PencilIcon className="w-6 text-neutral-400 hover:text-neutral-600"></PencilIcon></span>,
                deleteCell: <span className="cursor-pointer underline" onClick={() => setDeleteId(x._id)}><TrashIcon className="w-6 text-neutral-400 hover:text-neutral-600"></TrashIcon></span>
              }
            })} columns={[
              {
                Header: 'Name',
                accessor: 'name',
                width: 400
              },
              {
                Header: 'Edit',
                accessor: 'edit',
                width: 20
              },
              {
                Header: 'Delete',
                accessor: 'deleteCell',
                width: 20
              },
            ]}></Table>
        </div>
      </div>
    </div>
  </>
  )
}