import { useEffect, useState } from 'react'
import { api } from './api.service'
import { useParams } from "react-router-dom";
import Dashboard from './dashboard'

export default function RenderReport() {
  const params = useParams();
  const [metricValues, setMetricValues] = useState();
  const [metrics, setMetrics] = useState();
  const [scorecards, setScorecards] = useState();
  const [report, setReport] = useState();
  const [portfolioCompanies, setPortfolioCompanies] = useState();

  useEffect(() => {
    api(`${process.env.REACT_APP_API_URL}/report/${params.reportId}/${params.pcId}`).then(x => {
      setReport(x.report);
      setMetrics(x.metrics);
      setScorecards(x.scorecards);
      setMetricValues(x.metricValues);
      setPortfolioCompanies(x.portfolioCompanies);
    });
  }, [params.id])

  if (!metricValues || !metrics || !report) return <></>

  return (<div className="w-full h-full bg-white">
    <div className="w-[794px]">
      <Dashboard startDate={params.startDate} endDate={params.endDate} portfolioCompanies={portfolioCompanies} forRenderedReport={true} metrics={metrics} _metricValues={metricValues} dashboard={report} scorecards={scorecards}></Dashboard>
    </div>
  </div>
  )
}