export default function CurrencyField({ label, value, onChange, className, inputClass, labelClass, onBlur }) {
    return <div className={className}>
        <label className={`block text-sm font-medium leading-6 text-gray-900 ${labelClass}`}>
            {label}
        </label>
        <div className="relative mt-2 rounded-md shadow-sm inline-block">
            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                <span className="text-gray-500 sm:text-sm">{process.env.REACT_APP_CURRENCY_SYMBOL}</span>
            </div>
            <input onBlur={onBlur}
                type="number" value={value} onChange={(e) => onChange(e.target.value)}
                className={`w-full lock rounded-md border-0 py-1.5 pl-7 pr-12 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6 ${inputClass}`}
                placeholder="0.00"
            />
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                <span className="text-gray-500 sm:text-sm" id="price-currency">
                    {process.env.REACT_APP_CURRENCY}
                </span>
            </div>
        </div>
    </div>
}