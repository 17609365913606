import PageHeader from "../components/page-header";
import ImageField from "./fields/image-upload";
import TextAreaField from "./fields/text-area-field";
import CurrencyField from "./fields/currency-field";
import TextField from "./fields/text-field";
import FormWrapper from "./form-wrapper";
import { useNavigate, useParams } from "react-router-dom";
import DateField from "./fields/date-field";
import SelectField from "./fields/select-field";

export default function EvForm(props) {
    const params = useParams();
    let id = params.id ?? props.id;
    const navigate = useNavigate();
    return <div>
        <PageHeader headline="Manage EV"></PageHeader>
        <FormWrapper url="admin/ev" id={id} {...props} callback={() => navigate('/2/evs')}>
            {(values, setValues) => <div className="grid grid-cols-2 gap-2">
                <SelectField optionListName="pcs" label="Portfolio Company" value={values.portfolioCompany} onChange={(val) => setValues({ ...values, portfolioCompany: val })}></SelectField>
                <CurrencyField label="Valuation" value={values.valuation} onChange={(val) => setValues({ ...values, valuation: val })}></CurrencyField>
                <DateField label="Valuation Date" value={values.date} onChange={(val) => setValues({ ...values, date: val })}></DateField>
            </div>}
        </FormWrapper>
    </div>
}