import PageHeader from './components/page-header'
import Loading from './components/loading'
import { useEffect, useState } from 'react'
import { api } from './api.service'
import PcCard from './components/pc-card'
import { useNavigate } from 'react-router-dom'
import Button from './components/button'
export default function AdminPCs() {
  const navigate = useNavigate();
  const [data, setData] = useState(null)

  useEffect(() => { api(`${process.env.REACT_APP_API_URL}/admin/pc`).then(x => setData(x)) }, [])

  if (!data) return <Loading></Loading>

  return (<>
    <PageHeader headline={"Portfolio Companies"} subtitle={"List of all your Portfolio Companies"} actions={<Button text="Add PC" onClick={() => navigate('/2/manage-pc/new')}></Button>}></PageHeader>
    <div className="flex">
      <div className="w-full pb-20 max-w-6xl mx-auto">
        <div>
          <div className="grid grid-cols-4 gap-4 mt-5">
            {data.map(x => <PcCard editClick={() => navigate(`/2/manage-pc/${x._id}`)} onClick={() => navigate(`/2/pc/${x._id}`)} pc={x}></PcCard>)}
          </div>
        </div>
      </div>
    </div>
  </>
  )
}