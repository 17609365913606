import PageHeader from "../components/page-header";
import ImageField from "./fields/image-upload";
import TextAreaField from "./fields/text-area-field";
import TextField from "./fields/text-field";
import FormWrapper from "./form-wrapper";
import { useNavigate, useParams } from "react-router-dom";
import DateField from "./fields/date-field";

export default function InvestorForm(props) {
    const params = useParams();
    let id = params.id ?? props.id;
    const navigate = useNavigate();
    return <div>
        <PageHeader headline="Manage Investor"></PageHeader>
        <FormWrapper url="admin/investor" id={id} {...props} callback={() => navigate('/2/investors')}>
            {(values, setValues) => <div className="grid grid-cols-2 gap-2">
                <ImageField className="col-span-2" label="Profile Picture" value={values.picture} onChange={(val) => setValues({ ...values, picture: val })}></ImageField>
                <TextField label="Name" value={values.name} onChange={(val) => setValues({ ...values, name: val })}></TextField>
                <TextField label="Location" value={values.location} onChange={(val) => setValues({ ...values, location: val })}></TextField>
                <TextAreaField className="col-span-2" label="About" value={values.about} onChange={(val) => setValues({ ...values, about: val })}></TextAreaField>
            </div>}
        </FormWrapper>
    </div>
}