import { Popover } from '@headlessui/react'
import { FlagIcon, InformationCircleIcon } from '@heroicons/react/20/solid'

export default function FlagPopup({ text }) {
    return (
        <Popover className="absolute inline">
            <Popover.Button><div className="absolute top-1 -left-5"><FlagIcon className="text-red-500 h-4 w-4"></FlagIcon></div></Popover.Button>

            <Popover.Panel className="text-sm w-44 -left-20 text-center absolute z-10 rounded-xl shadow p-3 bg-neutral-700 border border-neutral-800 text-neutral-50">
                {text}
            </Popover.Panel>
        </Popover>
    )
}