import { ArrowDownOnSquareIcon, DocumentIcon } from "@heroicons/react/24/outline";
import { useState } from "react";
import { api } from "../api.service";
import FormModal from "../forms/form-modal";

export default function DocumentModal({ id }) {

    const [open, _setOpen] = useState(false)

    const [values, setValues] = useState({});

    const setOpen = (val) => {
        _setOpen(val);
        if (val) {
            api(`${process.env.REACT_APP_API_URL}/document/${id}`).then(x => setValues(x));
        }
    }

    const download = (url, name) => {
        fetch(`${process.env.REACT_APP_API_URL}/document/download-url/${url}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/pdf',
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
            },
        })
            .then((response) => response.blob())
            .then((blob) => {

                let extension = values.url?.split('.').pop();

                const url = window.URL.createObjectURL(
                    new Blob([blob]),
                );
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute(
                    'download',
                    `${name}.${extension}`,
                );

                document.body.appendChild(link);

                link.click();

                link.parentNode.removeChild(link);
            });
    }


    return <>
        <FormModal open={open} setOpen={setOpen}>

            <div className={`block leading-6 text-gray-900 text-2xl font-semibold mb-5`}>
                {values.name}
            </div>
            <div className={`block leading-6 text-gray-700 text-lg font-medium mb-5`}>
                {values.description}
            </div>


            <div className="flex">

                <div className="mx-auto"><ArrowDownOnSquareIcon onClick={() => download(values.url, values.name)} className=" bg-neutral-100 hover:bg-neutral-200 rounded-large shadow-lg p-2 w-10 text-neutral-800 cursor-pointer"></ArrowDownOnSquareIcon></div>
            </div>

        </FormModal>

        <div>

            <div onClick={(e) => { e.stopPropagation(); setOpen(true) }} className="shadow-lg cursor-pointer hover:bg-blue-200 rounded-full bg-blue-100 p-2 w-10 h-10 text-neutral-800">
                <DocumentIcon></DocumentIcon>
            </div>
        </div>

    </>

}
