import PageHeader from './components/page-header'
import Loading from './components/loading'
import { useEffect, useState } from 'react'
import { api, api_delete } from './api.service'
import PcCard from './components/pc-card'
import { useNavigate } from 'react-router-dom'
import Button from './components/button'
import InvestorCard from './components/investor-card'
import Table from './components/table'
import Confirm from './components/confirm'

export default function PcForms() {
  const navigate = useNavigate();
  const [data, setData] = useState(null)

  useEffect(() => { api(`${process.env.REACT_APP_API_URL}/pc/form`).then(x => setData(x)) }, [])

  if (!data) return <Loading></Loading>

  return (<>
    <PageHeader headline={"Forms"}></PageHeader>
    <div className="flex">
      <div className="w-full pb-20 max-w-6xl mx-auto my-10">
        <div>
          <Table
            data={data.map(x => {
              return {
                ...x, dueDate: new Date(x.dueDate).toDateString(),
                edit: <span className="cursor-pointer underline" onClick={() => navigate(`/0/form/${x._id}`)}>Input</span>
              }
            })} columns={[
              {
                Header: 'Name',
                accessor: 'name',
              },
              {
                Header: 'Category',
                accessor: 'category',
              },
              {
                Header: 'Due Date',
                accessor: 'dueDate',
              },
              {
                Header: 'Input Data',
                accessor: 'edit',
              }
            ]}></Table>
        </div>
      </div>
    </div>
  </>
  )
}