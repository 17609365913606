import PageHeader from './components/page-header'
import Loading from './components/loading'
import { Fragment, useEffect, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { api, api_delete } from './api.service'
import PcCard from './components/pc-card'
import { useNavigate } from 'react-router-dom'
import Button from './components/button'
import SelectField from './forms/fields/select-field'
import DateField from './forms/fields/date-field'
import Table from './components/table'
import Confirm from './components/confirm'
import { EyeIcon, PencilIcon, TrashIcon } from '@heroicons/react/20/solid'
import { ArrowDownTrayIcon } from '@heroicons/react/24/outline'

export default function AdminReports() {
  const navigate = useNavigate();
  const [data, setData] = useState(null)
  const [deleteId, setDeleteId] = useState();
  const [selectedReport, setSelectedReport] = useState();
  const deleteConfirm = () => {
    api_delete(`${process.env.REACT_APP_API_URL}/admin/report/${deleteId}`).then(x => {
      api(`${process.env.REACT_APP_API_URL}/admin/report`).then(x => setData(x)).then(x => setDeleteId(null))
    });
  }

  const download = (values) => {
    fetch(`${process.env.REACT_APP_API_URL}/admin/report-download/${values.id}/${values.pc}/${values.startDate}/${values.endDate}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/pdf',
        'Authorization': 'Bearer ' + localStorage.getItem('token'),
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(
          new Blob([blob]),
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          `Report.pdf`,
        );

        document.body.appendChild(link);

        link.click();

        link.parentNode.removeChild(link);
      });
  }

  useEffect(() => { api(`${process.env.REACT_APP_API_URL}/admin/report`).then(x => setData(x)) }, [])

  if (!data) return <Loading></Loading>

  return (<>
    <Confirm open={!!deleteId} cancel={() => setDeleteId(false)} confirm={() => deleteConfirm(deleteId)}></Confirm>
    <Modal callback={(values) => download(values)} open={!!selectedReport} setOpen={() => setSelectedReport(null)} report={selectedReport}></Modal>
    <PageHeader subtitle={"Manage and download PDF reports"} headline={"Reports"} actions={<Button text="Add Report" onClick={() => navigate('/2/manage-report/new')}></Button>}></PageHeader>
    <div className="flex">
      <div className="w-full pb-20 max-w-6xl mx-auto my-10">
        <div>
          <Table
            data={data.map(x => {
              return {
                ...x,
                preview: <span className="cursor-pointer underline" onClick={() => navigate(`/2/preview-report/${x._id}`)}><EyeIcon className="w-6 text-neutral-400 hover:text-neutral-600"></EyeIcon></span>,
                run: <span className="cursor-pointer underline" onClick={() => { setSelectedReport(x) }}><ArrowDownTrayIcon className="w-6 text-neutral-400 hover:text-neutral-600"></ArrowDownTrayIcon></span>,
                edit: <span className="cursor-pointer underline" onClick={() => navigate(`/2/manage-report/${x._id}`)}><PencilIcon className="w-6 text-neutral-400 hover:text-neutral-600"></PencilIcon></span>,
                deleteCell: <span className="cursor-pointer underline" onClick={() => setDeleteId(x._id)}><TrashIcon className="w-6 text-neutral-400 hover:text-neutral-600"></TrashIcon></span>
              }
            })} columns={[
              {
                Header: 'Name',
                accessor: 'name',
                width: 400
              },
              {
                Header: 'Preview',
                accessor: 'preview',
                width: 30
              },
              {
                Header: 'Run',
                accessor: 'run',
                width: 30
              },
              {
                Header: 'Edit',
                accessor: 'edit',
                width: 30
              },
              {
                Header: 'Delete',
                accessor: 'deleteCell',
                width: 30
              },
            ]}></Table>
        </div>
      </div>
    </div>
  </>
  )
}

function Modal({ open, setOpen, report, callback }) {


  const [values, setValues] = useState({})
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10 " onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-xl sm:p-6">
                <div>

                  {report && <div className="mt-3 text-center sm:mt-5 relative">
                    <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                      Report Settings
                    </Dialog.Title>
                    <div className="mt-2 relative z-40">
                      {!report.isAggregate && <SelectField label="Portfolio Company" optionListName={'pcs'} value={values.pc} onChange={(v) => setValues({ ...values, pc: v })}></SelectField>}
                    </div>
                    <DateField label="Start Date" value={values.startDate} onChange={(v) => setValues({ ...values, startDate: v })}></DateField>
                    <DateField label="End Date" value={values.endDate} onChange={(v) => setValues({ ...values, endDate: v })}></DateField>
                  </div>}
                </div>
                <div className="mt-5 sm:mt-6">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                    onClick={() => callback({ id: report._id, ...values })}
                  >
                    Download
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root >
  )
}