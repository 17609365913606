import ImageViewer from "./image-viewer"
import { Cog6ToothIcon } from "@heroicons/react/20/solid";
import {format_currency} from '../formatter'

export default function InvestorCard({ investor, invested, ev, editClick, onClick }) {
    return <div className="cursor-pointer rounded-md shadow-lg bg-white flex relative" onClick={() => onClick()}>
       {editClick &&  <div className="absolute top-2 right-2 select-none text-2xl font-bold text-neutral-400 hover:text-neutral-600 cursor-pointer" onClick={(e) => { e.stopPropagation(); editClick() }}>
            <Cog6ToothIcon className="h-8"></Cog6ToothIcon>
        </div>}
        <ImageViewer image={investor.picture} className="shadow h-40 object-cover rounded-l-md"></ImageViewer>
        <div className="flex-1 p-5 my-auto">
            <div className="text-neutral-800 text-xl font-semibold">{investor.name}</div>
            <div className="text-neutral-500 text-sm mb-4">{investor.location}</div>
            <div className="flex justify-between">
                <div className="text-neutral-700 font-semibold">Invested {format_currency(investor.investments?.reduce((acc, x) => acc += +x.invested, 0))}</div>
                <div className="text-neutral-700 font-semibold">{investor.Ev ? 'Portfolio EV ' + format_currency(investor.Ev) : 'No Valuations'}</div>
            </div>
        </div>
    </div>
}