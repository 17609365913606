import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import logo from './logo.png'

export default function Register() {

  const [searchParams, setSearchParams] = useSearchParams();

  const [values, setValues] = useState({});
  const [error, setError] = useState("")

  const submit = async () => {
    await fetch(`${process.env.REACT_APP_API_URL}/auth/register`, {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json'
      }),
      body: JSON.stringify({ user: values })
    }).then(res => { return res.json() }).then(x => {
      if (x.error) {
        if (x.error == "email_in_use") {
          setError("Email already in use. Please sign in or register with a different email.");
        }
      }
      else if (x.token) {
        localStorage.setItem("token", x.token);
      }
      else {
        setError("Registration failed. Please check your details and try again.")
      }
    })
  }

  return (
    <>
      <div className="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <img
            className="mx-auto h-36 w-auto"
            src={logo}
            alt="Your Company"
          />
          <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-blue-500">Register</h2>
          <p className="mt-2 text-center text-sm text-blue-300">
            Or{' '}
            <a href="/login" className="font-medium text-blue-400 hover:text-blue-600">
              sign in
            </a>
          </p>
        </div>
        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4  sm:rounded-lg sm:px-10">
            <div className="space-y-6">
              <div>
                <label className="block text-sm font-medium text-gray-700 text-center">
                  {error}
                </label>
              </div>

              <div>
                <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                  Full Name
                </label>
                <div className="mt-1">
                  <input value={values.name} onChange={(e) => setValues({ ...values, name: e.target.value })}
                    id="name"
                    name="name"
                    type="text"
                    required
                    className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-gray-500 focus:outline-none focus:ring-gray-500 sm:text-sm"
                  />
                </div>
              </div>

              <div>
                <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                  Email address
                </label>
                <div className="mt-1">
                  <input value={values.email} onChange={(e) => setValues({ ...values, email: e.target.value })}
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    required
                    className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-gray-500 focus:outline-none focus:ring-gray-500 sm:text-sm"
                  />
                </div>
              </div>

              <div>
                <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                  Password
                </label>
                <div className="mt-1">
                  <input value={values.password} onChange={(e) => setValues({ ...values, password: e.target.value })}
                    id="password"
                    name="password"
                    type="password"
                    autoComplete="current-password"
                    required
                    className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-gray-500 focus:outline-none focus:ring-gray-500 sm:text-sm"
                  />
                </div>
              </div>
              <div>
                <button
                  onClick={() => submit()}
                  type="button"
                  className="flex w-full justify-center rounded-md border border-transparent bg-blue-500 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                >
                  Create Account
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
