import PageHeader from "../components/page-header";
import TextField from "./fields/text-field";
import FormWrapper from "./form-wrapper";
import SelectField from "./fields/select-field";
import PasswordField from "./fields/password-field";
import { useNavigate, useParams } from "react-router-dom";

export default function UserForm(props) {
    const params = useParams();
    const navigate = useNavigate();
    let id = params.id ?? props.id;

    return <div>
        <PageHeader showBackButton headline="Manage User"></PageHeader>
        <FormWrapper url="admin/user" id={id} {...props} callback={() => navigate(-1)}>
            {(values, setValues) =>
                <>
                    <div className="text-neutral-800 border-b border-neutral-200 pb-1 my-5 font-semibold text-2xl">General</div>

                    <div className="grid grid-cols-2 gap-2">
                        <TextField label="Name" value={values.name} onChange={(val) => setValues({ ...values, name: val })}></TextField>
                        <TextField label="Email" value={values.email} onChange={(val) => setValues({ ...values, email: val })}></TextField>
                    </div>
                    <div className="grid grid-cols-2 gap-2">
                        <div>
                            <SelectField optionListName="pcs" label="Portfolio Company" help={"Leave blank if this is an admin user"} value={values.portfolioCompany} onChange={(val) => setValues({ ...values, portfolioCompany: val, investor: null })}></SelectField>
                            <div className="text-neutral-500 text-sm">Leave blank unless you wish to update this users password</div>

                        </div>

                        {/* <SelectField optionListName="investors" label="Investor" value={values.investor} onChange={(val) => setValues({ ...values, investor: val, portfolioCompany: null })}></SelectField> */}
                    </div>
                    <div className="text-neutral-800 border-b border-neutral-200 pb-1 my-5 font-semibold text-2xl">Change Password</div>

                    <PasswordField label="New Password" value={values.password} onChange={(val) => setValues({ ...values, password: val })}></PasswordField>
                    <div className="text-neutral-500 text-sm">Leave blank unless you wish to update this users password</div>
                </>
            }
        </FormWrapper>
    </div>
}