import PageHeader from './components/page-header'
import Loading from './components/loading'
import { useEffect, useState } from 'react'
import { api, api_delete } from './api.service'
import PcCard from './components/pc-card'
import Button from './components/button'
import InvestorCard from './components/investor-card'
import Table from './components/table'
import Confirm from './components/confirm'
import { useNavigate, useParams } from "react-router-dom";
import Dashboard from './dashboard'

export default function AdminDataDashboard() {
  const params = useParams();
  const [metricValues, setMetricValues] = useState();
  const [metrics, setMetrics] = useState();
  const [scorecards, setScorecards] = useState();
  const [dashboard, setDashboard] = useState();
  useEffect(() => { api(`${process.env.REACT_APP_API_URL}/admin/metric`).then(x => { { setMetrics(x) }; }); }, [])
  useEffect(() => { api(`${process.env.REACT_APP_API_URL}/admin/scorecard`).then(x => { { setScorecards(x) }; }); }, [])
  useEffect(() => { api(`${process.env.REACT_APP_API_URL}/admin/metric-values`).then(x => { { setMetricValues(x) }; }); }, [])
  useEffect(() => { api(`${process.env.REACT_APP_API_URL}/admin/dashboard/${params.id}`).then(x => { { setDashboard(x) }; }); }, [params.id])
  if (!metricValues || !metrics || !dashboard) return <Loading></Loading>

  return (<>
    <PageHeader headline={dashboard.name} ></PageHeader>
    <div className="max-w-6xl w-full mx-auto">
      <Dashboard metrics={metrics} _metricValues={metricValues} dashboard={dashboard} scorecards={scorecards}></Dashboard>
    </div>
  </>
  )
}