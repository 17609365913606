import PageHeader from "../components/page-header";
import ImageField from "./fields/image-upload";
import TextAreaField from "./fields/text-area-field";
import TextField from "./fields/text-field";
import FormWrapper from "./form-wrapper";
import { useNavigate, useParams } from "react-router-dom";
import DateField from "./fields/date-field";
import SelectField from "./fields/select-field";
import ColourField from "./fields/colour-field";
import Button from "../components/button";
import NumberField from "./fields/number-field";
import NumericBooleanBuilder from "./fields/numeric-boolean-builder";
import { useEffect, useState } from "react";
import { api } from "../api.service";
import { TrashIcon } from "@heroicons/react/24/outline";

export default function ScorecardForm(props) {
    const params = useParams();
    let id = params.id ?? props.id;
    const navigate = useNavigate();

    const [metrics, setMetrics] = useState()

    useEffect(() => { api(`${process.env.REACT_APP_API_URL}/admin/metric`).then(x => setMetrics(x)) }, [])

    const getMetric = (met) => {
        return metrics?.find(x => x._id == met.metric) ?? {}
    }

    return <div>
        <PageHeader showBackButton headline={`Manage Scorecard`}></PageHeader>
        <FormWrapper url="admin/scorecard" id={id} {...props} callback={() => navigate(-1)}>
            {(values, setValues) => <div>
                <div className="text-neutral-800 border-b border-neutral-200 pb-1 my-5 font-semibold text-2xl">General</div>

                <div className="grid grid-cols-3 gap-2">
                    <TextField label="Name" value={values.name} onChange={(val) => setValues({ ...values, name: val })}></TextField>
                    <TextField label="Category" value={values.category} onChange={(val) => setValues({ ...values, category: val })}></TextField>
                    <ColourField label="Colour" value={values.colour} onChange={(val) => setValues({ ...values, colour: val })}></ColourField>
                </div>
                <div className="text-neutral-800 border-b border-neutral-200 pb-1 my-5 font-semibold text-2xl">Scores</div>

                {values.metrics?.map((x, i) => <div className="border grid grid-cols-4 my-2 relative">
                    <div className="col-span-4 bg-neutral-200 p-2 relative text-neutral-800 font-medium text-xl">
                        Rule {i + 1}
                        <div className="absolute right-2 top-2" onClick={() => setValues({ ...values, metrics: values.metrics.filter((y, j) => j !== i) })}><TrashIcon className="w-6 text-neutral-600 hover:text-neutral-800 cursor-pointer"></TrashIcon></div>
                    </div>

                    <div className="border-r p-2">

                        <SelectField optionListName={'metrics'} label="Metric" value={x.metric} onChange={(val) => setValues({ ...values, metrics: values.metrics.map((y, j) => j === i ? { ...y, metric: val } : y) })}></SelectField>
                        <NumberField label="Max Score" value={x.maxScore} onChange={(val) => setValues({ ...values, metrics: values.metrics.map((y, j) => j === i ? { ...y, maxScore: val } : y) })}></NumberField>

                    </div>

                    <div className="col-span-3 p-2 bg-neutral-50">

                        {x.rules?.map((rule, ruleIndx) => <div className="flex flex-col border rounded-lg  relative bg-white my-2">
                            <div className="bg-neutral-200 relative rounded-t-lg p-1 px-3 text-neutral-800 font-medium">
                                Logic {ruleIndx + 1}

                                <div className="absolute right-1 top-1" onClick={() => setValues({ ...values, metrics: values.metrics.map((y, j) => j === i ? { ...y, rules: y.rules.filter((x, i) => i !== ruleIndx) } : y) })}><TrashIcon className="w-5 text-neutral-600 hover:text-neutral-800 cursor-pointer"></TrashIcon></div>

                            </div>
                            <div className="flex flex-1 space-x-2 p-2">
                                <div className="flex-1">
                                    {/* Computational  */}
                                    {(getMetric(x).computational) && <>
                                        <NumericBooleanBuilder value={rule.value} onChange={(val) => setValues({ ...values, metrics: values.metrics.map((y, j) => j === i ? { ...y, rules: y.rules.map((z, l) => l === ruleIndx ? { ...z, value: val } : z) } : y) })}></NumericBooleanBuilder>
                                    </>}

                                    {/* Answered or not  */}
                                    {(getMetric(x).type == 0 || getMetric(x).type == 1 || getMetric(x).type == 9) && <>
                                        <SelectField options={[{ text: 'Answered', value: true }, { text: 'Not Answered', value: false }]} label="Metric" value={rule.value} onChange={(val) => setValues({ ...values, metrics: values.metrics.map((y, j) => j === i ? { ...y, rules: y.rules.map((z, l) => l === ruleIndx ? { ...z, value: val } : z) } : y) })}></SelectField>
                                    </>}

                                    {/* Numeric */}
                                    {(getMetric(x).type == 2 || getMetric(x).type == 3 || getMetric(x).type == 4) && <>
                                        <NumericBooleanBuilder value={rule.value} onChange={(val) => setValues({ ...values, metrics: values.metrics.map((y, j) => j === i ? { ...y, rules: y.rules.map((z, l) => l === ruleIndx ? { ...z, value: val } : z) } : y) })}></NumericBooleanBuilder>
                                    </>}

                                    {/* Date */}
                                    {(getMetric(x).type == 5) && <>
                                        <NumericBooleanBuilder value={rule.value} onChange={(val) => setValues({ ...values, metrics: values.metrics.map((y, j) => j === i ? { ...y, rules: y.rules.map((z, l) => l === ruleIndx ? { ...z, value: val } : z) } : y) })}></NumericBooleanBuilder>
                                    </>}

                                    {/* Select */}
                                    {(getMetric(x).type == 6 || getMetric(x).type == 7) && <>
                                        <SelectField options={getMetric(x).options} label="Option" value={rule.value} onChange={(val) => setValues({ ...values, metrics: values.metrics.map((y, j) => j === i ? { ...y, rules: y.rules.map((z, l) => l === ruleIndx ? { ...z, value: val } : z) } : y) })}></SelectField>
                                    </>}

                                    {/* Yes No */}
                                    {(getMetric(x).type == 8) && <>
                                        <SelectField options={[{ text: 'Yes', value: true }, { text: 'No', value: false }]} label="Option" value={rule.value} onChange={(val) => setValues({ ...values, metrics: values.metrics.map((y, j) => j === i ? { ...y, rules: y.rules.map((z, l) => l === ruleIndx ? { ...z, value: val } : z) } : y) })}></SelectField>
                                    </>}

                                </div>
                                <div className="w-64">
                                    <NumberField label="Score" value={rule.score} onChange={(val) => setValues({ ...values, metrics: values.metrics.map((y, j) => j === i ? { ...y, rules: y.rules.map((z, l) => l === ruleIndx ? { ...z, score: val } : z) } : y) })}></NumberField>
                                </div>
                            </div>



                        </div>)}
                        <Button className="mt-5" text="Add Scoring Rule" onClick={() => setValues({ ...values, metrics: values.metrics.map((y, j) => j === i ? { ...y, rules: y.rules ? y.rules.concat([{}]) : [{}] } : y) })}></Button>

                    </div>
                </div>)}

                <Button className="mt-5" text="Add Metric" onClick={() => setValues({ ...values, metrics: values.metrics ? values.metrics.concat([{}]) : [{}] })}></Button>


            </div>}
        </FormWrapper>
    </div>
}