
import moment from 'moment';

export const DateStringToDate = (dateString, interval) => {
    if (!dateString) return ''
    if (interval == 0) {
        let parts = dateString.split(" ")[1]?.split("/");
        return moment(`${parts[2]}/${parts[1]}/${parts[0]}`).toDate()
    }
    if (interval == 1) {
        return moment(`${dateString.split(" ")[1]}/${dateString.split(" ")[0]}1`).toDate()
    }
    if (interval == 2) {
        let year = dateString.split(" ")[1];
        let quarter = dateString[1];
        let month = quarter == 1 ? 1 : quarter == 2 ? 4 : quarter == 3 ? 7 : 10;
        return moment(`${year}/${month}/1`).toDate()
    }
    if (interval == 3) {
        let year = dateString.split(" ")[1];
        let quarter = dateString[1];
        let month = quarter == 1 ? 1 : quarter == 2 ? 4 : quarter == 3 ? 7 : 10;
        return moment(`${year}/${month}/1`).toDate()
    }
    if (interval == 4) {
        return moment(dateString).toDate()
    }
}

export const GetDateString = (date, interval) => {
    if (interval == 0) return moment(date).format('[WC] DD/MM/YYYY')
    if (interval == 1) return moment(date).format('MMM YYYY')
    if (interval == 2) return moment(date).format('[Q]Q YYYY')
    if (interval == 3) return moment(date).format('[Q]Q YYYY')
    if (interval == 4) return moment(date).format('YYYY')
  }