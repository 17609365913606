import PageHeader from "../components/page-header";
import ImageField from "./fields/image-upload";
import TextAreaField from "./fields/text-area-field";
import CurrencyField from "./fields/currency-field";
import TextField from "./fields/text-field";
import FormWrapper from "./form-wrapper";
import { useNavigate, useParams } from "react-router-dom";
import DateField from "./fields/date-field";
import SelectField from "./fields/select-field";
import CheckboxField from "./fields/checkbox-field";

export default function NewsForm(props) {
    const params = useParams();
    let id = params.id ?? props.id;
    const navigate = useNavigate();
    return <div>
        <PageHeader headline="Manage News"></PageHeader>
        <FormWrapper url="admin/news" id={id} {...props} callback={() => navigate('/2/news')}>
            {(values, setValues) => <div className="grid grid-cols-2 gap-2">
                <TextField label="Title" value={values.title} onChange={(val) => setValues({ ...values, title: val })}></TextField>
                <TextField label="Subtitle" value={values.subtitle} onChange={(val) => setValues({ ...values, subtitle: val })}></TextField>
                <TextAreaField className="col-span-2" label="Body" value={values.body} onChange={(val) => setValues({ ...values, body: val })}></TextAreaField>
                <TextField label="URL" value={values.url} onChange={(val) => setValues({ ...values, url: val })}></TextField>
                <CheckboxField label="Live" value={values.live} onChange={(val) => setValues({ ...values, live: val })}></CheckboxField>
                <DateField label="Date" value={values.date} onChange={(val) => setValues({ ...values, date: val })}></DateField>
            </div>}
        </FormWrapper>
    </div>
}