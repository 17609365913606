import PageHeader from './components/page-header'
import Loading from './components/loading'
import { useEffect, useState } from 'react'
import { api, api_delete } from './api.service'
import PcCard from './components/pc-card'
import Button from './components/button'
import InvestorCard from './components/investor-card'
import Table from './components/table'
import Confirm from './components/confirm'
import { useNavigate, useParams } from "react-router-dom";
import Dashboard from './dashboard'
import SelectField from './forms/fields/select-field'

export default function AdminReport() {
  const params = useParams();
  const [metricValues, setMetricValues] = useState();
  const [metrics, setMetrics] = useState();
  const [scorecards, setScorecards] = useState();
  const [report, setReport] = useState();
  const [selectedPc, setSelectedPc] = useState();

  useEffect(() => { api(`${process.env.REACT_APP_API_URL}/admin/metric`).then(x => { { setMetrics(x) }; }); }, [])
  useEffect(() => { api(`${process.env.REACT_APP_API_URL}/admin/scorecard`).then(x => { { setScorecards(x) }; }); }, [])
  useEffect(() => { api(`${process.env.REACT_APP_API_URL}/admin/metric-values`).then(x => { { setMetricValues(x) }; }); }, [])
  useEffect(() => { api(`${process.env.REACT_APP_API_URL}/admin/report/${params.id}`).then(x => { { setReport(x) }; }); }, [params.id])
  if (!metricValues || !metrics || !report) return <Loading></Loading>

  return (<>
    <PageHeader headline={report.name} ></PageHeader>
    {report.isAggregate && <div className="max-w-6xl w-full mx-auto">
      <div className="bg-white p-10 shadow-md">
        <Dashboard metrics={metrics} _metricValues={metricValues} dashboard={report} scorecards={scorecards}></Dashboard>
      </div>
    </div>}
    {!report.isAggregate && <div className="max-w-6xl w-full mx-auto">
      <SelectField optionListName={'pcs'} label="Portfolio Company" value={selectedPc} onChange={(val) => setSelectedPc(val)}></SelectField>
      <div className="bg-white p-10 shadow-md">
        {selectedPc && <Dashboard metrics={metrics} _metricValues={metricValues.filter(x => x.portfolioCompany._id == selectedPc)} dashboard={report} scorecards={scorecards}></Dashboard>}

      </div>
    </div>}
  </>
  )
}