import ImageViewer from "./image-viewer"
import { Cog6ToothIcon } from "@heroicons/react/20/solid";
import {format_currency} from '../formatter'

export default function PcCard({ pc, editClick, onClick, investment, ev }) {
    return <div className="cursor-pointer rounded-md shadow-lg bg-white p-5 flex flex-col relative" onClick={() => onClick()}>
        {editClick && <div className="absolute top-2 right-2 select-none text-2xl font-bold text-neutral-400 hover:text-neutral-600 cursor-pointer" onClick={(e) => { e.stopPropagation(); editClick() }}>
            <Cog6ToothIcon className="h-8"></Cog6ToothIcon>
        </div>}
        <ImageViewer image={pc.logo} className="rounded-full shadow w-44 h-44 mx-auto object-cover mb-5"></ImageViewer>
        <div className="text-neutral-800 text-xl font-semibold">{pc.name}</div>
        <div className="text-neutral-500 text-sm">{pc.sector}</div>
        {/* <div className="flex flex-col justify-between">
            <div className="text-neutral-700 font-semibold">Invested {investment ? format_currency(investment.invested) : format_currency(pc.investments?.reduce((acc, x) => acc += +x.invested, 0))}</div>
            <div className="text-neutral-700 font-semibold">{ev ? 'Valuation ' + format_currency(ev) : pc.evs?.length ? 'Valuation ' + format_currency(pc.evs?.sort((a, b) => new Date(a) < new Date(b) ? -1 : 1).slice().reverse()[0].valuation) : 'Pre-Raise'}</div>
        </div> */}
    </div>
}