import PageHeader from './components/page-header'
import Loading from './components/loading'
import { useEffect, useState } from 'react'
import { api } from './api.service'
import PcCard from './components/pc-card'
import { useNavigate } from 'react-router-dom'
import Button from './components/button'
import InvestorCard from './components/investor-card'

export default function AdminInvestors() {
  const navigate = useNavigate();
  const [data, setData] = useState(null)

  useEffect(() => { api(`${process.env.REACT_APP_API_URL}/admin/investor`).then(x => setData(x)) }, [])

  if (!data) return <Loading></Loading>

  return (<>
    <PageHeader headline={"Investors"} actions={<Button text="Add Investor" onClick={() => navigate('/2/manage-investor/new')}></Button>}></PageHeader>
    <div className="flex">
      <div className="w-full pb-20 max-w-6xl mx-auto">
        <div>
          <div className="grid grid-cols-2 gap-4 mt-5">
            {data.map(x => <InvestorCard editClick={() => navigate(`/2/manage-investor/${x._id}`)} onClick={() => navigate(`/2/investor/${x._id}`)} investor={x}></InvestorCard>)}
          </div>
        </div>
      </div>
    </div>
  </>
  )
}