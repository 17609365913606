import { CheckIcon } from "@heroicons/react/20/solid";
import { ArrowUpOnSquareIcon, ArrowDownOnSquareIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import { api, api_file } from "../../api.service";
import Button from "../../components/button";
import FormModal from "../form-modal";
import TextAreaField from "./text-area-field";
import TextField from "./text-field";

export default function FileField({ label, value, onChange, className, inputClass, labelClass, placeholder, onBlur, endpoint }) {

    const [open, _setOpen] = useState(false)

    const [values, setValues] = useState(value ?? {});

    const setOpen = (val) => {
        _setOpen(val);
        if (val) {
            if (value)
                api(`${process.env.REACT_APP_API_URL}/document/${value}`).then(x => setValues(x));
        }
    }

    const uploadFile = (file) => {
        const formData = new FormData();
        formData.append('file', file);
        api_file(`${process.env.REACT_APP_API_URL}/${endpoint ? endpoint : 'admin'}/file`, formData).then(x => setValues({ ...values, url: x.name }));
    }

    const download = (url, name) => {
        fetch(`${process.env.REACT_APP_API_URL}/document/download-url/${url}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/pdf',
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
            },
        })
            .then((response) => response.blob())
            .then((blob) => {

                let extension = values.url?.split('.').pop();

                const url = window.URL.createObjectURL(
                    new Blob([blob]),
                );
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute(
                    'download',
                    `${name}.${extension}`,
                );

                document.body.appendChild(link);

                link.click();

                link.parentNode.removeChild(link);
            });
    }

    const save = () => {
        api(`${process.env.REACT_APP_API_URL}/document`, { data: values }).then(x => { onChange(x.id); setOpen(false) });
    }

    return <>
        <FormModal open={open} setOpen={setOpen}>

            <div className={`block leading-6 text-gray-900 text-2xl font-semibold mb-5 ${labelClass}`}>
                {label}
            </div>

            <TextField label="File Name" value={values.name} onChange={(val) => setValues({ ...values, name: val })}></TextField>
            <TextAreaField label="Notes" value={values.description} onChange={(val) => setValues({ ...values, description: val })}></TextAreaField>

            <div className={`block leading-6 text-gray-900 text-sm font-medium ${labelClass}`}>
                File
            </div>
            <div className="flex">
                <div className="flex-1 block text-xs font-medium text-zinc-700 text-left">
                    {values.url && <span><b>Uploaded File:</b> {values.url}</span>}
                </div>
                <div className="shrink-0"><ArrowDownOnSquareIcon onClick={() => download(values.url, values.name)} className="bg-neutral-200 hover:bg-neutral-300 rounded-large shadow p-2 w-10 text-neutral-800 cursor-pointer"></ArrowDownOnSquareIcon></div>
            </div>

            <label>
                <input
                    onChange={(e) => uploadFile(e.target.files[0])}
                    type="file"
                    name="file"
                    className="opacity-0 w-0 h-0"
                />
                <div class="text-center font-medium bg-gradient-to-b from-neutral-50 to-neutral-100 p-3 text-sm cursor-pointer text-zinc-700 hover:text-zinc-900">
                    {values.url && <span>Replace</span>}{!values.url && <span>Upload</span>} File
                </div>
            </label>

            <Button className="mt-5" text="Save" onClick={() => save()}></Button>

        </FormModal>

        <div className={className}>
            <div className={`block text-sm font-medium leading-6 text-gray-900 ${labelClass}`}>
                {label}
            </div>
            <div onClick={() => setOpen(true)} className="shadow cursor-pointer hover:bg-neutral-300 rounded-full bg-neutral-200 p-2 w-10 h-10 text-neutral-800 mx-auto">
                {value && <CheckIcon className="text-green-500"></CheckIcon>}
                {!value && <ArrowUpOnSquareIcon></ArrowUpOnSquareIcon>}
            </div>
        </div>

    </>

}
