import PageHeader from './components/page-header'
import Loading from './components/loading'
import { useEffect, useState } from 'react'
import { api, api_delete } from './api.service'
import PcCard from './components/pc-card'
import { useNavigate } from 'react-router-dom'
import Button from './components/button'
import InvestorCard from './components/investor-card'
import Table from './components/table'
import Confirm from './components/confirm'
import PcMetrics from './pc-metrics'
import SelectField from './forms/fields/select-field'

export default function AdminDataManage() {
  const navigate = useNavigate();
  const [pc, setPc] = useState()


  return (<>
    <PageHeader headline={"Manage Metrics Data"} subtitle={"Edit metrics on behalf of a PC"} ></PageHeader>
    <div className="flex">
      <div className="w-full pb-20 max-w-6xl mx-auto my-10">
        <div>
          <SelectField optionListName={'pcs'} label="Portfolio Company" value={pc} onChange={(val) => setPc(val)}></SelectField>
          {pc && <PcMetrics isAdmin pcId={pc}></PcMetrics>}
        </div>
      </div>
    </div>
  </>
  )
}